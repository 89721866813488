import React, { useState } from "react";
import Image from "next/image";
const DEFAULT_FALLBACK_SRC = "/images/common/img_default_3by4.png";
const BLUR_DATA_URL = "data:image/gif;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAFklEQVR42mN8//HLfwYiAOOoQvoqBABbWyZJf74GZgAAAABJRU5ErkJggg==";
export default function ImageFallback({
  src = "",
  width,
  height,
  alt,
  customThumbnail,
  fill,
  sizes,
  placeholder,
  fallbackSrc,
  ...rest
}) {
  const [imgErr, setImgErr] = useState(false);
  const srcCheck = src => {
    if (!!src && src.indexOf("https://") === -1 && src.indexOf("http://") === -1) {
      if (src[0] !== "/") {
        return "";
      }
    }
    return src;
  };
  return <>
      {(!src || imgErr) && <>
          {!!customThumbnail ? customThumbnail : <Image priority={true} fill={!!fill || false} sizes={!!fill ? sizes : ""} src={fallbackSrc || DEFAULT_FALLBACK_SRC} loading="eager" height={!!fill ? undefined : height} width={!!fill ? undefined : width} alt="defaultImg" {...rest} />}
        </>}
      {!!src && !imgErr && <Image src={srcCheck(src)} fill={!!fill || false} sizes={!!fill ? sizes : ""} placeholder={placeholder ? placeholder : "blur"} blurDataURL={BLUR_DATA_URL} height={!!fill ? undefined : height} width={!!fill ? undefined : width} alt={!!alt ? alt : "Image"} {...rest} onError={() => {
      setImgErr(true);
    }} />}
    </>;
}