import { ko } from "date-fns/locale";
import DatePicker from "react-datepicker";
import { forwardRef, useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import dayjs from "dayjs";
export default function DatePickerBox({
  dateFormat,
  searchData,
  setSearchData,
  closeOnScroll,
  className,
  changeHandler,
  openHandler,
  closeHandler,
  open
}) {
  const {
    t
  } = useTranslation("common");
  const {
    locale
  } = useRouter();
  const CustomInput = forwardRef(function Input({
    value,
    onClick
  }, ref) {
    const openDaysHandler = () => {
      onClick();
      if (openHandler) openHandler();
    };
    useEffect(() => {
      if (searchData?.showDaysLayer) {
        onClick();
        setSearchData({
          ...searchData,
          showDaysLayer: false
        });
      }
    }, [searchData?.showDaysLayer]);
    return <div className={`datepicker cursor-pointer ${className}`} ref={ref} onClick={openDaysHandler}>
        {value}
        {/*<span>{diffDate}</span>*/}
      </div>;
  });
  const [maxDate, setMaxDate] = useState();
  useEffect(() => {
    setMaxDate(dayjs().add(1, "years").endOf("month").toDate());
  }, []);

  // const [diffDate, setDiffDate] = useState(", 1" + t("mypage.txt.bookings.nights"));

  /*useEffect(() => {
    const diff = dayjs(searchData.endDate)
      .locale(locale)
      .diff(dayjs(searchData.startDate), "days");
    if (diff) {
      setDiffDate(t("searchbar.txt.date.day", { n: diff }));
    } else {
      setDiffDate(``);
    }
  }, [searchData.startDate, searchData.endDate]);*/

  const onChange = dates => {
    const [start, end] = dates;
    if (end && dayjs(end).diff(dayjs(start), "days") === 0) {
      return false;
    }
    setSearchData({
      ...searchData,
      startDate: start,
      endDate: end
    });
    if (!end) {
      setMaxDate(dayjs(start).add(28, "days").toDate());
    } else {
      setMaxDate(dayjs().add(1, "years").endOf("month").toDate());
      if (closeHandler) closeHandler();
    }
    if (changeHandler) changeHandler();
  };
  const handleCalendarClose = () => {
    if (!searchData.endDate) {
      setSearchData({
        ...searchData,
        endDate: dayjs(searchData.startDate).add(1, "days").toDate()
      });
    }
    if (closeHandler) closeHandler();
  };
  return <DatePicker open={open} selectsRange dateFormat={dateFormat} closeOnScroll={closeOnScroll} onChange={onChange} openToDate={searchData.endDate} minDate={dayjs().toDate()} maxDate={maxDate} startDate={searchData.startDate} endDate={searchData.endDate} monthsShown={2} locale={locale === "ko-KR" ? ko : null} disabledKeyboardNavigation onCalendarClose={handleCalendarClose} customInput={<CustomInput />} renderCustomHeader={({
    monthDate,
    customHeaderCount,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled
  }) => <div className="flex h-[48px] flex-row items-center justify-between">
          <button aria-label="Previous Month" className={`react-datepicker__navigation react-datepicker__navigation--previous`} style={customHeaderCount === 1 ? {
      visibility: "hidden"
    } : null} onClick={() => {
      if (!prevMonthButtonDisabled) {
        decreaseMonth();
      }
    }}>
            <span className={`react-datepicker__navigation-icon react-datepicker__navigation-icon--previous
                          ${prevMonthButtonDisabled && "disable"}`}>
              {"<"}
            </span>
          </button>
          <span className="react-datepicker__current-month">
            {monthDate.toLocaleString(locale, {
        month: "long",
        year: "numeric"
      })}
          </span>
          <button aria-label="Next Month" className={`react-datepicker__navigation react-datepicker__navigation--next`} style={customHeaderCount === 0 ? {
      visibility: "hidden"
    } : null} onClick={() => {
      if (!nextMonthButtonDisabled) {
        increaseMonth();
      }
    }}>
            <span className={`react-datepicker__navigation-icon react-datepicker__navigation-icon--next
                         ${nextMonthButtonDisabled && "disable"}`}>
              {">"}
            </span>
          </button>
        </div>} />;
}