import FilterBoxCard from "./FilterBoxCard";
import FilterBoxRecommend from "./FilterBoxRecommend";
import { Contains } from "@constants/contains";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "next-i18next";
import Tab from "@components/common/tab";
import { useDomain } from "@modules/hooks/useDomain";
import { useTablet } from "@modules/hooks/useMedia";
import styled from "styled-components";
import { useAtom } from "jotai/index";
import { mainPageVer2Atom, navbarSearchboxAtom } from "@store/global";
import FilterBoxRecommendV2 from "@components/layout/SearchHeader/FilterBoxRecommendV2";
import { useRouter } from "next/router";
export default function FilterBox({
  className,
  searchSearchBoxRef,
  searchTextRef,
  searchData,
  setSearchData,
  searchBoxCloseHandler,
  searchKeywords,
  searchMetaData,
  searchBoxTextFlag,
  filterBoxChangeCallback
}) {
  const {
    t
  } = useTranslation("common");
  const {
    locale
  } = useRouter();
  const router = useRouter();
  const isTTBB = useDomain();
  const filterBoxRef = useRef();
  const [searchBtnFirstClick, setSearchBtnFirstClick] = useState(true);
  const [selectTab, setSelectTab] = useState(0);
  const [mainPageVer2] = useAtom(mainPageVer2Atom); // 신규 메인페이지 분기
  const [navbarSearchBoxClick] = useAtom(navbarSearchboxAtom); //navbar에 있는 서치박스 클릭 여부

  const bodyClickHandler = useCallback(event => {
    if ((event.target.classList.contains("btnSearch") || event.target.id === "btnSearch") && searchBtnFirstClick) {
      setSearchBtnFirstClick(false);
      return;
    }
    if (searchSearchBoxRef) {
      if (!searchSearchBoxRef.current.contains(event.target) && !filterBoxRef.current.contains(event.target)) {
        searchTextRef.current?.blur();
        if (searchBoxCloseHandler && searchBoxTextFlag) searchBoxCloseHandler();
      }
    }
  }, []);
  const handler = (region_id, text, type, searchText) => {
    setSearchData({
      ...searchData,
      query: text,
      searchId: region_id,
      searchType: type ? type : Contains.REGION,
      searchText: searchText
    });
    if (searchBoxCloseHandler) searchBoxCloseHandler();
  };
  useEffect(() => {
    const handleClickOutside = event => {
      if (!navbarSearchBoxClick && searchSearchBoxRef && !searchSearchBoxRef.current.contains(event.target)) {
        searchBoxCloseHandler();
      }
    };
    window.addEventListener("mousedown", handleClickOutside);
    return () => {
      window.removeEventListener("mousedown", handleClickOutside);
    };
  }, [searchSearchBoxRef, searchBoxCloseHandler]);
  useEffect(() => {
    document.body.addEventListener("click", bodyClickHandler);
    return () => {
      document.body.removeEventListener("click", bodyClickHandler);
    };
  }, []);
  return isTTBB || !mainPageVer2 ? <FilterBoxDiv ref={filterBoxRef} className={`absolute left-0 top-[72px] z-10 mt-[12px] flex max-h-[520px] min-h-[342px] w-[440px] flex-shrink-0
                 flex-grow-0 flex-col items-end justify-start gap-[10px] overflow-y-auto overflow-x-hidden rounded-xl
                 bg-white py-3.5 shadow-[0px_8px_20px_0_rgba(0,0,0,0.08)] mobile:hidden ${className}`}>
      {!searchMetaData && <FilterBoxRecommend searchKeywords={searchKeywords} handler={handler} />}
      {!searchMetaData?.hotels && !searchMetaData?.regions || searchMetaData?.hotels.size === 0 && searchMetaData?.regions.size === 0 && <div className="flex h-[310px] w-full flex-col flex-wrap items-center justify-center text-Gray-500 tablet:text-Gray-500">
            {t("search.title.nodata")}
          </div>}
      {searchMetaData?.regions && searchMetaData?.regions.size > 0 && <FilterBoxCard type={Contains.REGION} searchData={searchData} dataList={searchMetaData?.regions} handler={handler} />}
      {searchMetaData?.hotels && searchMetaData?.hotels.size > 0 && <FilterBoxCard type={Contains.HOTEL} searchData={searchData} dataList={searchMetaData?.hotels} handler={handler} />}
    </FilterBoxDiv> : <div ref={filterBoxRef} className={`absolute left-[-20px] z-10 mt-[12px] flex max-h-[500px] min-h-[374px] w-[440px] flex-col overflow-y-auto overflow-x-hidden rounded-3xl
                 bg-white pb-3.5 pt-1 shadow-[0px_8px_20px_0_rgba(0,0,0,0.08)] mobile:hidden ${router.asPath === "/" ? navbarSearchBoxClick ? "top-[72px]" : "top-[60px]" : "top-[72px]"} ${className}`}>
      {/*탭 영역*/}
      {!searchMetaData && (router.pathname === "/" || router.pathname === "/search/mobile") && <>
          <Tab tabArr={locale === "ko-KR" ? [t("main.popular.toggle.internal"), t("main.popular.toggle.external"), t("main.search.recent.title")] : [t("main.popular.toggle.internal"), "", t("main.search.recent.title")]} selectTab={selectTab} calssName="flex h-[48px] items-center text-center px-4" tabBtnClass="relative flex h-full px-2 py-[14px] cursor-pointer items-center justify-center mr-2.5" customTabBtn={(v, i) => {
        return <>
                  <span className="B_16_100_Bold text-Gray-900">{v}</span>
                  {selectTab === i ? <div className="absolute bottom-0 h-[3px] w-full rounded-t bg-Gray-900"></div> : <div className=""></div>}
                </>;
      }} callbacks={i => {
        setSelectTab(i);
      }} />
          <hr className="text-Gray-50" />
        </>}

      <div className="max-h-[500px] min-h-[374px] overflow-y-auto pt-2.5">
        {!searchMetaData && <FilterBoxRecommendV2 keywords={searchKeywords} tab={selectTab} handler={filterBoxChangeCallback} searchData={searchData} />}
        {!searchMetaData?.hotels && !searchMetaData?.regions && !searchMetaData?.recommend_keyword || searchMetaData?.hotels.size === 0 && searchMetaData?.regions.size === 0 && searchMetaData?.recommend_keyword.size === 0 && <div className="relative px-4 pb-0.5">
                <div className="flex h-[230px] w-full flex-col flex-wrap items-center justify-center text-Gray-500 tablet:text-Gray-500">
                  {t("search.title.nodata")}
                </div>
                <div className="flex flex-col gap-3 rounded-[10px] bg-Bg-OffWhite px-3 py-4">
                  <span className="SB_14_100_Bold h-[14px] text-Primary600">
                    {t("main.search.nosearch.title")}
                  </span>
                  <ul className="C_12_150_Regular list-disc pl-5 text-Gray-500">
                    {t("main.search.nosearch.description").replace(/\\n/g, "\n").split("\n").filter(innerValue => innerValue.trim() !== "").map((innerValue, inerIndex) => <li key={inerIndex} className="">
                          {innerValue}
                        </li>)}
                  </ul>
                </div>
              </div>}
        {searchMetaData?.recommend_keyword && searchMetaData?.recommend_keyword.size > 0 && <FilterBoxCard type={Contains.RECOMMEND} searchData={searchData} dataList={searchMetaData?.recommend_keyword} handler={filterBoxChangeCallback} />}
        {searchMetaData?.regions && searchMetaData?.regions.size > 0 && <FilterBoxCard type={Contains.REGION} searchData={searchData} dataList={searchMetaData?.regions} handler={handler} />}
        {searchMetaData?.hotels && searchMetaData?.hotels.size > 0 && <FilterBoxCard type={Contains.HOTEL} searchData={searchData} dataList={searchMetaData?.hotels} handler={handler} />}
      </div>
    </div>;
}
const FilterBoxDiv = styled.div.withConfig({
  displayName: "FilterBox__FilterBoxDiv",
  componentId: "sc-190k9qc-0"
})(["::-webkit-scrollbar{width:12px;}::-webkit-scrollbar-thumb{background-color:#b3b3b3;background-clip:padding-box;border:4px solid transparent;border-radius:20px;}::-webkit-scrollbar-track{border-radius:20px;background-color:white;margin:12px 0;}"]);